import React from "react";
import { graphql } from "gatsby";
import { SRLWrapper } from "simple-react-lightbox";
import get from "lodash/get";
import Img from "gatsby-image";
import Meta from "../components/meta";
import Layout from "../components/layout";
import Section from "../components/section";
import s from "./artist.module.css";

export const lightboxOptions = {
  buttons: {
    showDownloadButton: false,
    showThumbnailsButton: false,
    showAutoplayButton: false,
  },
  thumbnails: {
    showThumbnails: false,
  },
  caption: {
    showCaption: false,
  },
};
class ArtistTemplate extends React.Component {
  render() {
    const artist = get(this.props, "data.contentfulArtist", {});
    const defaultMeta = get(this.props, "data.site.siteMetadata");

    const jsonLD = {
      "@context": "https://schema.org",
      "@type": "person",
      name: artist.name,
      image: get(artist, "photo.fluid.src"),
      url: artist.socialLinks && artist.socialLinks[0].url,
    };

    return artist ? (
      <Layout location={this.props.location}>
        <div>
          <Meta jsonLD={jsonLD} defaultMeta={defaultMeta} title={artist.name} />

          <div className="wrapper">
            <SRLWrapper options={lightboxOptions}>
              <Section
                heading={artist.name}
                body={artist.bio}
                theme="dark"
                variant="artist"
                photo={artist.photo}
                useH1
              />

              <section className="theme--light u-container-x u-container-y--top">
                <div className={`u-container-content ${s.artworkWrap}`}>
                  {artist.art.map((a) => {
                    return (
                      a.storyArt && (
                        <div className={s.art} key={a.id}>
                          <Img alt={a.alt} fluid={a.image?.fluid} />
                        </div>
                      )
                    );
                  })}
                </div>
              </section>

              <Section heading="BEHIND THE SCENES" body={artist.behindText} />

              <section className="theme--light u-container-x">
                <div className={`u-container-content ${s.artworkWrap}`}>
                  {artist.artwork.map((a) => {
                    return a.image ? (
                      <div className={s.artwork} key={a.id}>
                        <Img alt={a.alt} fluid={a.image?.fluid} />
                      </div>
                    ) : null;
                  })}
                </div>

                <div className="u-container-content">
                  <p className={s.socialText}>{artist.socialText}</p>

                  {artist.socialLinks && (
                    <ul className="cta-group">
                      {artist.socialLinks.map((link) => (
                        <li key={link.id}>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            className={`cta`}
                            href={link.url}
                          >
                            {link.label}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </section>
            </SRLWrapper>
          </div>
        </div>
      </Layout>
    ) : (
      <div>error</div>
    );
  }
}

export default ArtistTemplate;

export const pageQuery = graphql`
  query ArtistBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        description
        siteUrl
        image
        socialInstagram
        socialTwitter
      }
    }
    contentfulArtist(slug: { eq: $slug }) {
      name
      slug
      bio {
        json
      }
      behindText {
        json
      }
      photo {
        fluid(maxWidth: 600) {
          ...GatsbyContentfulFluid
        }
      }
      artwork {
        alt
        type
        id

        image {
          fluid(maxWidth: 1240) {
            ...GatsbyContentfulFluid
          }
        }
      }
      socialText
      socialLinks {
        id
        label
        url
      }
      art {
        alt
        storyArt
        image {
          fluid(maxWidth: 1240) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`;
